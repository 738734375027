import _objectWithoutProperties from "/var/lib/jenkins/jobs/EV/jobs/NextJS/jobs/02-cert-build/workspace/target/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _defineProperty from "/var/lib/jenkins/jobs/EV/jobs/NextJS/jobs/02-cert-build/workspace/target/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var _excluded = ["enableStorage", "page"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { createSlice } from "@reduxjs/toolkit";
import createStorage from "services/storage";
var Storage = createStorage("START-PAGE");
var initialState = {
  QuickAndExpert: {
    // databasemask: 0,
    // date: null,
    // languages: null,
    // doctypes: null,
    // sortby: null,
    // autostemming: false,
    // disciplines: null,
    // treatmenttypes: null,
  },
  Quick: {}
};
export var StartPageSlice = createSlice({
  name: "startPage",
  initialState: initialState,
  reducers: {
    sync: function sync(state, _ref) {
      var _ref$payload = _ref.payload,
        page = _ref$payload === void 0 ? state.page : _ref$payload;
      var storaged = Storage.get();
      if (page) {
        var _storaged$page;
        state[page] = (_storaged$page = storaged === null || storaged === void 0 ? void 0 : storaged[page]) !== null && _storaged$page !== void 0 ? _storaged$page : {};
      }
      state[page].isSynced = true;
    },
    setDefaults: function setDefaults(state, _ref2) {
      var _ref2$payload = _ref2.payload,
        page = _ref2$payload.page,
        defaults = _ref2$payload.defaults;
      var storaged = Storage.get();
      if (defaults && !(storaged !== null && storaged !== void 0 && storaged[page]) && (!(state !== null && state !== void 0 && state[page]) || Object.keys(defaults).some(function (key) {
        return !Object.keys(state[page]).includes(key);
      }))) {
        state[page] = _objectSpread(_objectSpread({}, defaults), state[page]);
      }
    },
    update: function update(state, _ref3) {
      var _ref3$payload = _ref3.payload,
        enableStorage = _ref3$payload.enableStorage,
        page = _ref3$payload.page,
        rest = _objectWithoutProperties(_ref3$payload, _excluded);
      state[page] = _objectSpread(_objectSpread({}, state[page]), rest);
      if (enableStorage) Storage.set(state);
    },
    clear: function clear(state, _ref4) {
      var _initialState$page;
      var _ref4$payload = _ref4.payload,
        page = _ref4$payload.page,
        enableStorage = _ref4$payload.enableStorage;
      state[page] = (_initialState$page = initialState[page]) !== null && _initialState$page !== void 0 ? _initialState$page : {};
      if (enableStorage) Storage.set(state);
    }
  }
});
var actions = StartPageSlice.actions;
export { actions };
export var pages = function pages(state) {
  return state.startPage;
};
export default StartPageSlice.reducer;