import _defineProperty from "/var/lib/jenkins/jobs/EV/jobs/NextJS/jobs/02-cert-build/workspace/target/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { createSlice } from "@reduxjs/toolkit";
import createStorage from "services/storage";
var Storage = createStorage("PENDO-COUNT");
var initialState = {
  status: "init",
  entities: {},
  isSynced: false,
  useLocalStorage: false
};
var pendoCountSlice = createSlice({
  name: "pendoCountContainer",
  initialState: initialState,
  reducers: {
    useLocalStorage: function useLocalStorage(state, _ref) {
      var useLocalStorage = _ref.payload;
      state.useLocalStorage = useLocalStorage;
      if (useLocalStorage) {
        Storage.set(state);
      }
    },
    syncStorage: function syncStorage(state) {
      if (state.useLocalStorage) {
        var _storaged = Storage.get();
        if (_storaged) {
          state.entities = _objectSpread({}, _storaged.entities);
        }
      }
      state.isSynced = true;
    },
    setEntry: function setEntry(state, _ref2) {
      var _ref2$payload = _ref2.payload;
      _ref2$payload = _ref2$payload === void 0 ? {} : _ref2$payload;
      var key = _ref2$payload.key,
        value = _ref2$payload.value;
      if (state.entities[key]) {
        state.entities[key] = value;
      } else if (!state.entities[key]) {
        state.entities = _objectSpread(_objectSpread({}, state.entities ? state.entities : {}), {}, _defineProperty({}, key, value));
      }
      if (state.useLocalStorage) {
        Storage.set(state);
      }
    },
    deleteEntry: function deleteEntry(state, _ref3) {
      var key = _ref3.payload;
      delete state.entities[key];
      if (state.useLocalStorage) {
        Storage.set(state);
      }
    },
    clearEntries: function clearEntries(state) {
      state.entities = _objectSpread({}, initialState.entities);
      if (state.useLocalStorage) {
        Storage.set(state);
      }
      state.isSynced = true;
    }
  }
});
export var selectMapNumEntries = function selectMapNumEntries(state) {
  return Object.keys(state.pendoCount.entities).length;
};
export var selectMapEntries = function selectMapEntries(state) {
  return state.pendoCount.entities;
};
export var selectMapStatus = function selectMapStatus(state) {
  return state.pendoCount.entities.status;
};
export var selectMapKeys = function selectMapKeys(state) {
  return Object.keys(state.pendoCount.entities);
};
export var selectMapEntryExists = function selectMapEntryExists(state, key) {
  return !!state.pendoCount.entities[key];
};
var actions = pendoCountSlice.actions;
export { actions };
export default pendoCountSlice.reducer;