import _defineProperty from "/var/lib/jenkins/jobs/EV/jobs/NextJS/jobs/02-cert-build/workspace/target/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
// strip out markup html tags from controlled term if needed
var cleanControlledTerm = function cleanControlledTerm(term) {
  var holdTerm = term;
  if (holdTerm === null) return null;
  // remove beginning mark tags
  holdTerm = holdTerm.replace(/(<mark([^>]+)>)/gi, "");
  // remove ending mark tags
  holdTerm = holdTerm.replace(/(<\/mark([^>]?)>)/gi, "");
  return holdTerm;
};
export function linkOut(content) {
  window.Analytics.push(_objectSpread({
    event: "linkOut"
  }, content));
}
export function searchResultsClick(content) {
  window.Analytics.push(_objectSpread({
    event: "searchResultsClick"
  }, content));
}
export function clickControlledTerm(_ref) {
  var controlledTerm = _ref.controlledTerm;
  var cleanedControlledTerm = cleanControlledTerm(controlledTerm);
  window.Analytics.push({
    event: "buttonClick",
    buttonType: "ControlledTerm:".concat(cleanedControlledTerm)
  });
}