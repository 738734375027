import _defineProperty from "/var/lib/jenkins/jobs/EV/jobs/NextJS/jobs/02-cert-build/workspace/target/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { handleEventData } from "./tools";
export function load(_ref) {
  var json = _ref.json,
    searchHistoryEntity = _ref.searchHistoryEntity;
  window.Analytics.push(_objectSpread({
    event: "newPage",
    page: {
      name: "record:detail",
      type: "CP-CA",
      sectionName: "record"
    }
  }, handleEventData(json, searchHistoryEntity)));
}
export function loadReferences(_ref2) {
  var json = _ref2.json,
    searchHistoryEntity = _ref2.searchHistoryEntity,
    refType = _ref2.refType;
  window.Analytics.push(_objectSpread({
    event: "newPage",
    page: {
      name: "record:".concat(refType),
      type: "CP-CI",
      sectionName: "record"
    }
  }, handleEventData(json, searchHistoryEntity)));
}