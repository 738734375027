import _defineProperty from "/var/lib/jenkins/jobs/EV/jobs/NextJS/jobs/02-cert-build/workspace/target/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var EVENTS = {
  autoSuggest: "ev:search:autosuggest",
  register: "ev:search:register",
  startPageView: "search:quick",
  databaseDrawerClicked: "database:selection"
};
export function autoSuggest(value) {
  window.Analytics.push({
    event: "searchFeatureClick",
    page: {
      name: EVENTS.autoSuggest,
      type: "CP-CI",
      sectionName: "ev:search"
    },
    search: {
      featureName: "".concat(EVENTS.autoSuggest, ":").concat(value ? "on" : "off")
    }
  });
}
export function register() {
  window.Analytics.push({
    event: "conversionDriverClick",
    eventName: EVENTS.register,
    page: {
      name: "personal:loginregister",
      type: "AP-UR",
      sectionName: "personal",
      event: EVENTS.register
    },
    conversionDriver: {
      name: "ev:register:button:click"
    }
  });
}
export function startPageView() {
  window.Analytics.push({
    event: "newPage",
    page: {
      name: EVENTS.startPageView,
      type: "SP-SR",
      sectionName: "search",
      event: EVENTS.startPageView
    }
  });
}
export function spGuiToggle() {
  window.Analytics.push({
    event: "buttonClick",
    buttonType: "gui_toggle:oldSPView"
  });
}
export function changeStartPage(content) {
  window.Analytics.push(_objectSpread({
    event: "searchResultsClick"
  }, content));
}
export function databaseDrawerClicked() {
  window.Analytics.push({
    event: "newPage",
    page: {
      name: EVENTS.databaseDrawerClicked,
      type: "CP-CI",
      sectionName: "ev:database"
    }
  });
}
export function exploreDatabases() {
  window.Analytics.push({
    event: "buttonClick",
    buttonType: "[database-explore]:[click]"
  });
}
export function applyDatabaseChanges() {
  window.Analytics.push({
    event: "buttonClick",
    buttonType: "[database-selection]:[apply]"
  });
}