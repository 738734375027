import _defineProperty from "/var/lib/jenkins/jobs/EV/jobs/NextJS/jobs/02-cert-build/workspace/target/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { createSlice } from "@reduxjs/toolkit";
export var initialStateBase = {
  index: 0,
  pageSize: 0,
  current: undefined,
  prev: undefined,
  next: undefined,
  searchId: undefined,
  searchType: undefined,
  totalCount: 0
};
var initialState = _objectSpread({
  status: "init"
}, initialStateBase);
var cspSearchScrollSlice = createSlice({
  name: "cspsearchscroll",
  initialState: initialState,
  reducers: {
    setCspSearchScrollState: function setCspSearchScrollState(state, action) {
      state.status = action.payload.status;
      state.index = action.payload.index;
      state.pageSize = action.payload.pageSize;
      state.current = action.payload.current;
      state.prev = action.payload.prev;
      state.next = action.payload.next;
      state.totalCount = action.payload.totalCount;
      state.searchId = action.payload.searchId;
      state.searchType = action.payload.searchType;
    }
  }
});
var setCspSearchScrollState = cspSearchScrollSlice.actions.setCspSearchScrollState;
export { setCspSearchScrollState };
export var selectCspSearchScrollState = function selectCspSearchScrollState(state) {
  return state.cspsearchscroll;
};
export var selectCspSearchScrollStatus = function selectCspSearchScrollStatus(state) {
  return state.cspsearchscroll.status;
};
export default cspSearchScrollSlice.reducer;