import _defineProperty from "/var/lib/jenkins/jobs/EV/jobs/NextJS/jobs/02-cert-build/workspace/target/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useSelector } from "react-redux";
import { selectSessionSettings } from "redux/reducers/sessionSettingsReducer";
import useStartPageContext from "./useStartPageContext";
function useQuickAndExpertContext(props) {
  var _useSelector = useSelector(selectSessionSettings),
    userMask = _useSelector.userMask,
    sort = _useSelector.sort;
  var defaultState = {
    databasemask: userMask,
    sort: sort
  };
  // console.log("defaultState: ", defaultState);
  var getContext = useStartPageContext(_objectSpread({
    page: "QuickAndExpert",
    defaultState: defaultState
  }, props));
  var _getContext = getContext(),
    quickAndExpertState = _getContext.state,
    setQuickAndExpertState = _getContext.setState;
  return {
    quickAndExpertState: quickAndExpertState,
    setQuickAndExpertState: setQuickAndExpertState
  };
}
export default useQuickAndExpertContext;