import _objectWithoutProperties from "/var/lib/jenkins/jobs/EV/jobs/NextJS/jobs/02-cert-build/workspace/target/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _toConsumableArray from "/var/lib/jenkins/jobs/EV/jobs/NextJS/jobs/02-cert-build/workspace/target/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _defineProperty from "/var/lib/jenkins/jobs/EV/jobs/NextJS/jobs/02-cert-build/workspace/target/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var _excluded = ["booleans", "sections", "searchWords"],
  _excluded2 = ["autostem"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
export function transformSearchFields() {
  var fields = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var transformed = fields.reduce(function (total, _ref, index) {
    var _total$booleans, _total$sections, _total$searchWords;
    var _boolean = _ref["boolean"],
      section = _ref.section,
      search = _ref.search;
    if (index < 3) {
      var _objectSpread2;
      return _objectSpread(_objectSpread(_objectSpread({}, total), index > 0 ? _defineProperty({}, "boolean".concat(index), _boolean) : {}), {}, (_objectSpread2 = {}, _defineProperty(_objectSpread2, "section".concat(index + 1), section), _defineProperty(_objectSpread2, "searchWord".concat(index + 1), search), _objectSpread2));
    }
    return _objectSpread(_objectSpread({}, total), {}, {
      booleans: [].concat(_toConsumableArray((_total$booleans = total === null || total === void 0 ? void 0 : total.booleans) !== null && _total$booleans !== void 0 ? _total$booleans : []), [_boolean]),
      sections: [].concat(_toConsumableArray((_total$sections = total === null || total === void 0 ? void 0 : total.sections) !== null && _total$sections !== void 0 ? _total$sections : []), [section]),
      searchWords: [].concat(_toConsumableArray((_total$searchWords = total === null || total === void 0 ? void 0 : total.searchWords) !== null && _total$searchWords !== void 0 ? _total$searchWords : []), [search])
    });
  }, {});
  var _transformed$booleans = transformed.booleans,
    booleans = _transformed$booleans === void 0 ? [] : _transformed$booleans,
    _transformed$sections = transformed.sections,
    sections = _transformed$sections === void 0 ? [] : _transformed$sections,
    _transformed$searchWo = transformed.searchWords,
    searchWords = _transformed$searchWo === void 0 ? [] : _transformed$searchWo,
    rest = _objectWithoutProperties(transformed, _excluded);
  return _objectSpread(_objectSpread({}, rest), {}, {
    // booleanStr: booleans.filter((item) => !!item).join("|"),
    // sectionStr: sections.join("|"),
    // searchphrases: searchWords.join("|"),
    sections: sections,
    booleans: booleans.filter(function (item) {
      return !!item;
    }),
    searchWords: searchWords
  });
}
export function transformQuickAndExpertState(_ref3) {
  var _autostem$join;
  var autostem = _ref3.autostem,
    rest = _objectWithoutProperties(_ref3, _excluded2);
  return _objectSpread({
    autostem: (_autostem$join = autostem === null || autostem === void 0 ? void 0 : autostem.join("|")) !== null && _autostem$join !== void 0 ? _autostem$join : ""
  }, rest);
}
export var generateID = function generateID() {
  var length = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 6;
  return Math.random().toString(36).substring(2, length + 2);
};