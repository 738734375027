import { Databases as DatabasesBase } from "components/applogic/Databases";

// Get the entitled databases - exclude non-standalone backfiles!
export var getNobackfile = function getNobackfile(userEntitlements) {
  var holdEntitlements = userEntitlements ? userEntitlements.filter(function (entitlement) {
    return entitlement.entitlementType === "DATABASE";
  }) : [];

  // don't show backfile if CPX is displayed
  if (holdEntitlements.find(function (_ref) {
    var name = _ref.name;
    return name === "cpx";
  }) && holdEntitlements.find(function (_ref2) {
    var name = _ref2.name;
    return name === "c84";
  })) {
    holdEntitlements = holdEntitlements.filter(function (entitlement) {
      return entitlement.name !== "c84";
    });
  }
  if (holdEntitlements.find(function (_ref3) {
    var name = _ref3.name;
    return name === "cpx";
  }) && holdEntitlements.find(function (_ref4) {
    var name = _ref4.name;
    return name === "zbf";
  })) {
    holdEntitlements = holdEntitlements.filter(function (entitlement) {
      return entitlement.name !== "zbf";
    });
  }
  // don't show backfile if INS is displayed
  if (holdEntitlements.find(function (_ref5) {
    var name = _ref5.name;
    return name === "ins";
  }) && holdEntitlements.find(function (_ref6) {
    var name = _ref6.name;
    return name === "ibf";
  })) {
    holdEntitlements = holdEntitlements.filter(function (entitlement) {
      return entitlement.name !== "ibf";
    });
  }
  if (holdEntitlements.find(function (_ref7) {
    var name = _ref7.name;
    return name === "ins";
  }) && holdEntitlements.find(function (_ref8) {
    var name = _ref8.name;
    return name === "ibs";
  })) {
    holdEntitlements = holdEntitlements.filter(function (entitlement) {
      return entitlement.name !== "ibs";
    });
  }
  if (holdEntitlements.find(function (_ref9) {
    var name = _ref9.name;
    return name === "iap";
  })) {
    holdEntitlements = holdEntitlements.filter(function (entitlement) {
      return entitlement.name !== "iap";
    });
  }
  return holdEntitlements;
};

// Get the entitled databases - exclude non-standalone backfiles!
export var removeEncompass = function removeEncompass(userEntitlements) {
  var holdEntitlements = userEntitlements ? userEntitlements.filter(function (entitlement) {
    return entitlement.entitlementType === "DATABASE";
  }) : [];
  if (holdEntitlements.find(function (_ref10) {
    var name = _ref10.name;
    return name === "ept";
  })) {
    holdEntitlements = holdEntitlements.filter(function (entitlement) {
      return entitlement.name !== "ept";
    });
  }
  if (holdEntitlements.find(function (_ref11) {
    var name = _ref11.name;
    return name === "elt";
  })) {
    holdEntitlements = holdEntitlements.filter(function (entitlement) {
      return entitlement.name !== "elt";
    });
  }
  return holdEntitlements;
};
export var checkForBackfile = function checkForBackfile(dbMask, userEntitlements) {
  var holdEntitlements = userEntitlements ? userEntitlements.filter(function (entitlement) {
    return entitlement.entitlementType === "DATABASE";
  }) : [];

  // don't show backfile if CPX is displayed
  if (dbMask === 1 && holdEntitlements.find(function (_ref12) {
    var name = _ref12.name;
    return name === "cpx";
  }) && holdEntitlements.find(function (_ref13) {
    var name = _ref13.name;
    return name === "c84" || name === "zbf";
  })) {
    return DatabasesBase.zbf;
  }
  if (dbMask === 2 && holdEntitlements.find(function (_ref14) {
    var name = _ref14.name;
    return name === "ins";
  }) && holdEntitlements.find(function (_ref15) {
    var name = _ref15.name;
    return name === "ibf" || name === "ibs";
  })) {
    return DatabasesBase.ibs;
  }
  return null;
};

// Convenience method to determine if a database is entitled and should be shown.
export var isEntitled = function isEntitled(_ref16) {
  var mask = _ref16.mask,
    userEntitlements = _ref16.userEntitlements;
  return userEntitlements ? userEntitlements.findIndex(function (entitlement) {
    return entitlement.entitlementType === "DATABASE" && (entitlement === null || entitlement === void 0 ? void 0 : entitlement.mask) === mask;
  }) !== -1 : false;
};
export var getDatabases = function getDatabases(_ref17) {
  var nobackfile = _ref17.nobackfile;
  return nobackfile.map(function (entitlement) {
    return DatabasesBase[entitlement.name.toLowerCase()];
  }).sort(function (a, b) {
    return a.order - b.order;
  });
};
export var getDefaultDbValue = function getDefaultDbValue(_ref18) {
  var nobackfile = _ref18.nobackfile,
    enabledFences = _ref18.enabledFences;
  return nobackfile.filter(function (entitlement) {
    return (enabledFences === null || enabledFences === void 0 ? void 0 : enabledFences[entitlement.name.toUpperCase()]) === 1;
  }).reduce(function (prev, cur) {
    return prev + cur.mask;
  }, 0);
};
export var getAllDbValue = function getAllDbValue(_ref19) {
  var nobackfile = _ref19.nobackfile;
  return nobackfile.reduce(function (prev, cur) {
    return prev + cur.mask;
  }, 0);
};