import _defineProperty from "/var/lib/jenkins/jobs/EV/jobs/NextJS/jobs/02-cert-build/workspace/target/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import * as generals from "./generals";
import * as conferences from "./conferences";
import * as startPage from "./startPage";
import * as combinedRecordPage from "./combinedRecordPage";
import * as docs from "./docs";
import * as semanticSearch from "./semanticSearch";
var Events = _objectSpread(_objectSpread({}, generals), {}, {
  conferences: conferences,
  startPage: startPage,
  combinedRecordPage: combinedRecordPage,
  docs: docs,
  semanticSearch: semanticSearch
});
export { Events };