import _defineProperty from "/var/lib/jenkins/jobs/EV/jobs/NextJS/jobs/02-cert-build/workspace/target/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { handleEventData } from "./tools";
export function exportSubmit(content) {
  window.Analytics.push(_objectSpread({
    event: "contentExport",
    page: {
      name: "record:detail",
      type: "CP-CL",
      sectionName: "record"
    }
  }, content));
}
export function saveRecordToFolderSubmit(content) {
  window.Analytics.push(_objectSpread({
    event: "saveToList",
    page: {
      name: "personal:folder:save",
      type: "SP-SS",
      sectionName: "personal"
    }
  }, content));
}
export function saveRecordToFolderModal(json) {
  window.Analytics.push(_objectSpread({
    event: "newPage",
    page: {
      name: "personal:folder:save",
      type: "SP-SS",
      sectionName: "personal"
    }
  }, handleEventData(json)));
}
export function print(content) {
  window.Analytics.push(_objectSpread({
    event: "newPage",
    page: {
      location: "record:detail",
      name: "print:delivery:success",
      type: "MP-ER",
      sectionName: "EV:print"
    }
  }, content));
}