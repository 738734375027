import _typeof from "/var/lib/jenkins/jobs/EV/jobs/NextJS/jobs/02-cert-build/workspace/target/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/typeof.js";
import _defineProperty from "/var/lib/jenkins/jobs/EV/jobs/NextJS/jobs/02-cert-build/workspace/target/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/* eslint-disable no-param-reassign */

export function createElement(element, options) {
  return new Promise(function (resolve, reject) {
    var Element = Object.assign(document.createElement(element), options);
    document.head.appendChild(Element);
    Element.addEventListener("load", function () {
      return resolve(Element);
    });
    Element.addEventListener("error", function (e) {
      Element.remove();
      reject(e);
    });
  });
}
export function prepare(_ref, defaults) {
  var _adobeScriptTag$repla, _adobeScriptTag$repla2, _adobeScriptTag$repla3;
  var adobeScriptTag = _ref.adobeScriptTag,
    _ref$claims$analytics = _ref.claims.analytics_info,
    _ref$claims$analytics2 = _ref$claims$analytics.accessType,
    accessType = _ref$claims$analytics2 === void 0 ? "ae:unreg_ip" : _ref$claims$analytics2,
    accountId = _ref$claims$analytics.accountId,
    _ref$claims$analytics3 = _ref$claims$analytics.accountName,
    accountName = _ref$claims$analytics3 === void 0 ? "" : _ref$claims$analytics3,
    userId = _ref$claims$analytics.userId,
    consortiumId = _ref$claims$analytics.consortiumId,
    userAnonymity = _ref.user.userAnonymity,
    sisId = _ref.sessionId.id,
    ipAddress = _ref.auth.ipAddress;
  var src = adobeScriptTag === null || adobeScriptTag === void 0 ? void 0 : (_adobeScriptTag$repla = adobeScriptTag.replace) === null || _adobeScriptTag$repla === void 0 ? void 0 : (_adobeScriptTag$repla2 = _adobeScriptTag$repla.call(adobeScriptTag, /.* src=\"/, "")) === null || _adobeScriptTag$repla2 === void 0 ? void 0 : (_adobeScriptTag$repla3 = _adobeScriptTag$repla2.replace) === null || _adobeScriptTag$repla3 === void 0 ? void 0 : _adobeScriptTag$repla3.call(_adobeScriptTag$repla2, /\".*/, "");
  if (src.includes("development")) {
    defaults.page.environment = "dev";
  } else if (src.includes("staging")) {
    defaults.page.environment = "cert";
  }
  defaults.visitor = _objectSpread(_objectSpread({}, defaults.visitor), {}, {
    accessType: accessType.toLowerCase(),
    accountId: accountId,
    accountName: accountName.toLowerCase(),
    userId: userId,
    consortiumId: consortiumId,
    loginStatus: userAnonymity === "INDIVIDUAL" ? "logged in" : "anonymous",
    sisId: sisId,
    ipAddress: ipAddress
  });
  return {
    src: src
  };
}
export function setLoadTime() {
  var loadTime;
  if (typeof performance !== "undefined" && _typeof(performance.timing) === "object") {
    var _startTime = performance.timing.redirectStart || performance.timing.fetchStart || performance.timing.requestStart;
    var _endTime = performance.timing.domContentLoadedEventEnd || performance.timing.domInteractive || performance.timing.domComplete || performance.timing.loadEventEnd;
    if (_startTime && _endTime && _startTime < _endTime) {
      loadTime = _endTime - _startTime;
    }
  }
  return {
    loadTime: loadTime
  };
}
export function isProduction() {
  if (typeof window === "undefined") return false;
  var subdomain = window.location.host.split(".")[0];
  return !!(subdomain === "www" || subdomain === "www2" || subdomain === "");
}